import React from "react";

import "./sidebar.css";
import { Link } from "react-router-dom";
import logo from "../../assets/HomeSphere.jpeg";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";
  return (
    <div className="sidebar_item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  const logout = () => {
    localStorage.removeItem("uid");
    props.history.replace("/auth/login");
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" style={{ width: 180, height: 60 }} />
      </div>
      {sidebar_items.map((item, index) => (
        <Link to={item.route} key={index}>
          <SidebarItem
            title={item.display_name}
            icon={item.icon}
            active={index === activeItem}
          />
        </Link>
      ))}
      <div
        className="sidebar_item"
        style={{ position: "absolute", bottom: 0, cursor: "pointer" }}
        onClick={logout}
      >
        <div className={`sidebar__item-inner`}>
          <i className={"bx bx-user-x"}></i>
          <span>Logout</span>
        </div>
        {/* <div className="sidebar__bottom__logo">
          <img
            src={hizzy}
            alt="company logo"
            // style={{ width: 180, height: 60 }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
