import "./Invite.css";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Typography, Chip, Box, Button } from "@mui/material";

const Invite = () => {
  return (
    <div className="col-12">
      <h2 className="page-header">Builders Link</h2>
      <div className="card" style={{ padding: "100px 0" }}>
        <div className="card__body">
          <Box textAlign={"center"}>
            <Typography variant="h4" mb={"30px"} sx={{ fontSize: "25px" }}>
              Customers Invite Link For Builders to join Hizzy-Homesphere
            </Typography>
            <CopyToClipboard text={`https://link.myhizzy.com`}>
              <Chip
                variant="outlined"
                label={`https://link.myhizzy.com`}
                style={{
                  fontSize: "16px",
                  padding: "20px 220px",
                  borderRadius: "50px",
                }}
              />
            </CopyToClipboard>
            <br />
            <CopyToClipboard text={`https://link.myhizzy.com`}>
              <Button
                variant="contained"
                style={{
                  fontSize: 16,
                  color: "white",
                  width: "170px",
                  fontWeight: "500",
                  marginTop: "15px",
                  background:
                    "linear-gradient(to right, #ff0101 0%, #7c0404 200%)",
                }}
              >
                copy
              </Button>
            </CopyToClipboard>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Invite;
