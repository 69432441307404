import React, { useEffect } from "react";

import "./topnav.css";
import { Link } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import Close from "@mui/icons-material/Close";
import ThemeMenu from "../thememenu/ThemeMenu";
import { doc, getDoc, database } from "../../db/firebase";
import { Modal, Box, Typography, Grid } from "@mui/material";
import user_menu from "../../assets/JsonData/user_menus.json";
import notifications from "../../assets/JsonData/notification.json";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const renderNotificationItem = (item, index) => (
  <div className="notification-item" key={index}>
    <i className={item.icon}></i>
    <span>{item.content}</span>
  </div>
);

const renderUserToggle = (name) => (
  <div className="topnav__right-user">
    <div className="topnav__right-user__image">
      {/* <img src={"https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"} alt="" /> */}
    </div>
    <div className="topnav__right-user__name">{name}</div>
  </div>
);

const logout = () => {
  localStorage.removeItem("uid");
  window.location.href = "/auth/login";
};

const renderUserMenu = (item, index) => {
  if (item.route === "/auth/login") {
    return (
      <div
        className="notification-item"
        onClick={() => logout()}
        style={{ cursor: "pointer" }}
      >
        <i className={item.icon}></i>
        <span>{item.content}</span>
      </div>
    );
  } else {
    return (
      <Link to={item.route} key={index}>
        <div className="notification-item">
          <i className={item.icon}></i>
          <span>{item.content}</span>
        </div>
      </Link>
    );
  }
};

const Topnav = (props) => {
  const [name, setName] = React.useState();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onHandleClose = (item) => {
    setAnchorEl(null);
    props.history.push(item.route);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const isNew = await localStorage.getItem("isNew");
    if (isNew === true) {
      setOpen(true);
      localStorage.removeItem("isNew");
    }
    const uid = await localStorage.getItem("uid");
    const docSnap = await getDoc(doc(database, "builders", uid));
    if (docSnap.exists()) {
      setName(docSnap.data().fname + " " + docSnap.data().lname);
    }
  };

  const logout = () => {
    localStorage.removeItem("uid");
    props.history.replace("/auth/login");
    window.location.reload();
  };

  return (
    <div className="topnav">
      <MenuIcon
        aria-controls={menuOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
        className="menuIcon"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={onHandleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {sidebar_items.map((item, index) => (
          <MenuItem key={index} onClick={() => onHandleClose(item)}>
            {item.display_name}
          </MenuItem>
        ))}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
      <div className="topnav__search">
        <input type="text" placeholder="Search here..." />
        <i className="bx bx-search"></i>
      </div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          <Dropdown
            customToggle={() => renderUserToggle(name)}
            contentData={user_menu}
            renderItems={(item, index) => renderUserMenu(item, index)}
          />
        </div>
        <div className="topnav__right-item">
          <Dropdown
            icon="bx bx-bell"
            badge="12"
            contentData={notifications}
            renderItems={(item, index) => renderNotificationItem(item, index)}
            renderFooter={() => <Link to="/admin/services">View All</Link>}
          />
        </div>
        <div className="topnav__right-item">
          <ThemeMenu />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item display="flex" justifyContent={"space-between"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Take a tour
            </Typography>
            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
          </Grid>
          <Box style={{ textAlign: "center", marginTop: 100 }}>
            <h1 className="text">Under construction</h1>
            <h3 className="bottom-text">
              We will let you know when this page is up and running{" "}
            </h3>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Topnav;
