import {
  Box,
  Grid,
  Chip,
  Modal,
  Button,
  Select,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/material";
import "./users.css";
import Swal from "sweetalert2";
import { styled } from "@mui/system";
import { Delete } from "@mui/icons-material";
import Table from "../components/table/Table";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React, { useCallback, useEffect, useState } from "react";
import { Close, ArrowBack, OpenInNew } from "@mui/icons-material";
import {
  collection,
  getDocs,
  database,
  query,
  where,
  doc,
  deleteDoc,
} from "../db/firebase";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const builderTableHead = [
  "Builder",
  "Email",
  "Phone",
  "Clients",
  "Active Services",
  "Revenue",
  "Details",
  "Action",
];

const customerTableHead = [
  "Customer",
  "Email",
  "Phone",
  "Clients",
  "Active Services",
  "Revenue",
  "Details",
  "Action",
];

const CssButtton = styled(Button)(() => ({
  color: "red",
  borderColor: "red",

  "&:hover": {
    color: "red",
    borderColor: "red",
  },
}));

const renderHead = (item, index) => (
  <th
    key={index}
    style={{
      borderRight: "1px solid silver",
      textAlign: "center",
      marginTop: "10px",
    }}
  >
    {item}
  </th>
);

const renderBody = (item, index, handleInfoButton, handleDelete) => {
  const name = item.fname + " " + item.lname;
  return (
    <tr key={index} style={{ backgroundColor: index % 2 ? "#fafafa" : "#fff" }}>
      <td style={{ textAlign: "center" }}>{name || "Unknown"}</td>
      <td style={{ textAlign: "center" }}>{item.email || "- - - -"}</td>
      <td style={{ textAlign: "center" }}>{item.phoneNumber || "- - - - -"}</td>
      <td style={{ textAlign: "center" }}>{Math.round(Math.random() * 20)}</td>
      <td style={{ textAlign: "center" }}>{Math.round(Math.random() * 20)}</td>
      <td style={{ textAlign: "center" }}>
        ${Math.round(Math.random() * 200)}
      </td>
      <td style={{ textAlign: "center" }}>
        <CssButtton variant="outlined" onClick={() => handleInfoButton(item)}>
          Info
        </CssButtton>
      </td>
      <td style={{ textAlign: "center" }}>
        <IconButton onClick={() => handleDelete(item)}>
          <Delete />
        </IconButton>
      </td>
    </tr>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [userType, setUserType] = useState("builders");
  const [builderUsers, setBuilderUsers] = useState([]);
  const [selectedbuilder, setSelectedBuilders] = useState(null);
  const [serviceCategories, setServiceCategories] = useState([]);
  const usersList = useSelector((state) => state.storeDb.users) || [];
  const buildersList = useSelector((state) => state.storeDb.builders) || [];

  const getServices = useCallback(async () => {
    let array = [];
    const snap = await getDocs(collection(database, "serviceCategories"));
    snap.forEach((doc) => array.push(doc.data()));
    setServiceCategories(array);
  }, []);

  useEffect(() => {
    getServices();
  }, [getServices]);

  const getBuilders = useCallback(async () => {
    if (buildersList.length === 0) {
      const builders = [];
      getDocs(
        query(
          collection(database, "builders"),
          where("company", "==", "homesphere")
        )
      ).then((docSnap) => {
        docSnap.forEach((doc) => builders.push(doc.data()));
        dispatch({ type: "SET_BUILDERS", payload: builders });
        setBuilderUsers(builders);
      });
    }
  }, [dispatch, buildersList.length]);

  const getCustomers = useCallback(async () => {
    if (usersList.length === 0) {
      const users = [];
      getDocs(
        query(
          collection(database, "users"),
          where("company", "==", "homesphere")
        )
      ).then((docSnap) => {
        docSnap.forEach((doc) => users.push(doc.data()));
        dispatch({ type: "SET_USERS", payload: users });
      });
    }
  }, [dispatch, usersList.length]);

  useEffect(() => {
    getBuilders();
    getCustomers();
  }, [getCustomers, getBuilders]);

  const handleInfoButton = (item) => {
    if (userType === "builders") {
      if (openInfo) {
        setOpen(true);
        setSelectedBuilders(item);
      } else {
        let usersFilter = usersList.filter((e) => e.builder === item.uid);
        setBuilderUsers(usersFilter);
        setSelectedBuilders(item);
        setOpenInfo(true);
      }
    } else {
      setOpen(true);
      setSelectedBuilders(item);
    }
  };

  const handleDelete = async (item) => {
    if (userType === "builders") {
      let buildersFilter = buildersList.filter((e) => e.uid !== item.uid);
      dispatch({ type: "SET_BUILDERS", payload: buildersFilter });
      await deleteDoc(doc(database, "builders", item.uid));
    } else {
      let usersFilter = usersList.filter((e) => e.uid !== item.uid);
      dispatch({ type: "SET_USERS", payload: usersFilter });
      await deleteDoc(doc(database, "users", item.uid));
    }
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedBuilders(item);
  };

  const handleClose = () => setOpen(false);

  let tvImg = serviceCategories.find((e) => e.title === "tv");
  let solarImg = serviceCategories.find((e) => e.title === "solar");
  let pestImg = serviceCategories.find((e) => e.title === "pestControl");
  let securityImg = serviceCategories.find((e) => e.title === "Security");
  let internetImg = serviceCategories.find((e) => e.title === "Internet");

  return (
    <div>
      <div className="col-12">
        <div className="card">
          <div className="headerRow">
            <h1>Users</h1>
            <Select
              label="Age"
              value={userType}
              variant="standard"
              id="demo-simple-select"
              style={{ width: "200px" }}
              labelId="demo-simple-select-label"
              onChange={(e) => {
                setOpenInfo(false);
                setUserType(e.target.value);
              }}
            >
              <MenuItem value="customers">Customers</MenuItem>
              <MenuItem value="builders">Builders</MenuItem>
            </Select>
          </div>
          <Typography>{`users > ${userType} ${
            openInfo
              ? `> ${selectedbuilder?.fname + " " + selectedbuilder?.lname}`
              : ""
          }`}</Typography>
          {openInfo && (
            <div className="headerRow">
              <Typography
                sx={{ fontSize: "22px", color: "black", cursor: "pointer" }}
                onClick={() => handleOpen(selectedbuilder)}
              >
                {selectedbuilder?.fname + " " + selectedbuilder?.lname}
                <OpenInNew sx={{ fontSize: "20px", marginLeft: "5px" }} />
              </Typography>
              <CopyToClipboard
                text={`https://homesphare.myhizzy.com/register/builder=${selectedbuilder.uid}&company=homesphere`}
                onCopy={() =>
                  Swal.fire({
                    timer: 1500,
                    icon: "success",
                    position: "top-end",
                    showConfirmButton: false,
                    title: "Link copied to clipboard",
                  })
                }
              >
                <Chip
                  variant="outlined"
                  label={`https://homesphare.myhizzy.com/register/builder=${selectedbuilder.uid}&company=homesphere`}
                  size="medium"
                />
              </CopyToClipboard>
            </div>
          )}
          <Table
            limit="10"
            headData={
              userType === "builders" ? builderTableHead : customerTableHead
            }
            renderBody={(item, index) =>
              renderBody(item, index, handleInfoButton, handleDelete)
            }
            renderHead={(item, index) => renderHead(item, index)}
            bodyData={
              openInfo
                ? builderUsers
                : userType === "builders"
                ? buildersList
                : usersList
            }
          />
          {openInfo && builderUsers.length === 0 && (
            <div
              className="card__footer"
              style={{ marginTop: "60px", marginBottom: "30px" }}
            >
              <h1 className="text">No customers found of this builder.</h1>
            </div>
          )}
          {userType === "builders" && buildersList.length === 0 && (
            <div
              className="card__footer"
              style={{ marginTop: "60px", marginBottom: "30px" }}
            >
              <h1 className="text">No {userType} Found!</h1>
            </div>
          )}
          {userType === "customers" && usersList.length === 0 && (
            <div
              className="card__footer"
              style={{ marginTop: "60px", marginBottom: "30px" }}
            >
              <h1 className="text">No {userType} Found!</h1>
            </div>
          )}
          {openInfo && (
            <CssButtton
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => setOpenInfo(false)}
              style={{ padding: "8px 30px", marginTop: "30px" }}
            >
              Back
            </CssButtton>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} id="modal-popup">
          <Close
            onClick={handleClose}
            style={{
              top: 20,
              right: 20,
              cursor: "pointer",
              position: "absolute",
            }}
          />
          {selectedbuilder && (
            <Box id="user-wrapper">
              <Grid item id="top-modal">
                <div style={{ width: 200 }}>
                  <img
                    src={
                      selectedbuilder?.userImg
                        ? selectedbuilder?.userImg
                        : "https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
                    }
                    style={{
                      width: 70,
                      height: 70,
                      objectFit: "cover",
                      borderRadius: "100px",
                    }}
                    alt="users profile"
                    id="selectedbuilder-image"
                  />
                  <Typography sx={{ fontSize: 16 }}>
                    {selectedbuilder?.fname + " " + selectedbuilder?.lname}
                  </Typography>
                </div>
                <div id="info-section">
                  <div className="info-box">
                    <Grid
                      item
                      ml={"10px"}
                      display={"flex"}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          margin: "3px 0",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                      >
                        Email:{" "}
                        {selectedbuilder.email ? selectedbuilder.email : "N/A"}
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          margin: "3px 0",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                      >
                        Phone: {selectedbuilder?.phoneNumber || "N/A"}
                      </Typography>
                    </Grid>
                  </div>
                  <div className="info-center">
                    <Grid
                      item
                      ml={"10px"}
                      display={"flex"}
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          margin: "3px 0",
                        }}
                      >
                        House Completion Data:
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          margin: "3px 0",
                          fontSize: "15px",
                          textAlign: "center",
                        }}
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        N/A
                        {/* {selectedbuilder?.houseCompletingDate
                          ? selectedbuilder.houseCompletingDate
                          : "N/A"} */}
                      </Typography>
                    </Grid>
                  </div>
                  <div className="info-box">
                    <Grid
                      item
                      ml={"10px"}
                      justifyContent="center"
                      display={"flex"}
                      flexDirection="column"
                    >
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          margin: "3px 0",
                        }}
                      >
                        Address:
                      </Typography>
                      <Typography
                        variant="h5"
                        component="h5"
                        sx={{
                          fontSize: "15px",
                          textAlign: "center",
                          margin: "3px 0",
                        }}
                      >
                        {selectedbuilder?.address} {selectedbuilder?.city}
                      </Typography>
                    </Grid>
                  </div>
                </div>
              </Grid>

              <br />
              <br />

              {userType !== "builders" ? (
                <>
                  <Typography variant="h6" component="h6" marginTop={"50px"}>
                    Active Services
                  </Typography>

                  {selectedbuilder?.services && (
                    <div className="servicesRow">
                      {selectedbuilder.services.internet && (
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="service-logo">
                            <img
                              src={internetImg.image}
                              className="serviceIMG"
                              alt="internet"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                      {selectedbuilder.services.pest && (
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="service-logo">
                            <img
                              src={pestImg.image}
                              className="serviceIMG"
                              alt="internet"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                      {selectedbuilder.services.security && (
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="service-logo">
                            <img
                              src={securityImg?.image}
                              className="serviceIMG"
                              alt="security"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                      {selectedbuilder.services.solar && (
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="service-logo">
                            <img
                              src={solarImg.image}
                              className="serviceIMG"
                              alt="solar"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                      {selectedbuilder.services.tv && (
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="service-logo">
                            <img
                              alt="tv"
                              src={tvImg.image}
                              className="serviceIMG"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Created</th>
                        <th>Activity</th>
                        <th style={{ textAlign: "center", width: "150px" }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Aptive</td>
                        <td>11/12/22</td>
                        <td>Active</td>
                        <td style={{ textAlign: "center", width: "150px" }}>
                          <Button
                            variant="contained"
                            style={{ width: "160px" }}
                          >
                            Installed
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Aptive</td>
                        <td>11/12/22</td>
                        <td>Active</td>
                        <td style={{ textAlign: "center", width: "150px" }}>
                          <Button
                            variant="contained"
                            style={{ width: "160px" }}
                          >
                            Installed
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Aptive</td>
                        <td>11/12/22</td>
                        <td>Active</td>
                        <td style={{ textAlign: "center", width: "150px" }}>
                          <Button
                            variant="contained"
                            style={{ width: "160px", background: "skyblue" }}
                          >
                            In Progress
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Aptive</td>
                        <td>11/12/22</td>
                        <td>Active</td>
                        <td style={{ textAlign: "center", width: "150px" }}>
                          <Button
                            variant="contained"
                            style={{ width: "160px", background: "tomato" }}
                          >
                            Cancelled
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default Users;
