import React, { useEffect } from "react";
import "./analytics.css";
import { Link } from "react-router-dom";
// import StatusCard from "../components/status-card/StatusCard";

import Table from "../components/table/Table";

import Badge from "../badge/Badge";

// import statusCards from "../assets/JsonData/status-card-data.json";

import Chart from "react-apexcharts";

import { useSelector, useDispatch } from "react-redux";

import ThemeAction from "../redux/actions/ThemeAction";

const chartOptions = {
  series: [
    {
      name: "Online Customers",
      data: [40, 70, 10, 80, 36, 80, 46, 98, 72],
    },
    {
      name: "Store Customers",
      data: [49, 26, 53, 68, 34, 65, 13, 65],
    },
  ],
  options: {
    color: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const topCustomers = {
  head: ["user", "total orders", "total spending"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const renderCustomerHead = (item, index) => {
  return <th key={index}>{item}</th>;
};

const renderCustomerBody = (item, index) => (
  <tr key={index}>
    <td>{item.user}</td>
    <td>{item.order}</td>
    <td>{item.price}</td>
  </tr>
);

const latestOrders = {
  header: ["order id", "user", "total price", "date", "status"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const orderStatus = {
  shipping: "primary",
  pending: "warning",
  paid: "success",
  refund: "danger",
};

const renderOrderHead = (item, index) => {
  return <th key={index}>{item}</th>;
};
const renderOrderBody = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.id}</td>
      <td>{item.user}</td>
      <td>{item.price}</td>
      <td>{item.date}</td>
      <td>
        <Badge type={orderStatus[item.status]} content={item.status} />
      </td>
    </tr>
  );
};

const Analytics = () => {
  // const [test, setTest] = usestate()

  // useEffect ------{

  // axios.get(sgdfgsd).then(setTest)

  // },[]

  const store = useSelector((state) => state.storeDb.mode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ThemeAction.getTheme());
  });

  return (
    <div>
      <h2 className="page-header">Analytics</h2>
      <div>
        <h1 className="text">Under construction</h1>
      </div>
      <div className="row">
        {/* Boxes */}
        {/* <div className="col-6">
          <div className="row">
            {statusCards.map((item, index) => {
              return (
                <div className="col-6" key={index}>
                  <StatusCard
                    icon={item.icon}
                    count={item.count}
                    title={item.title}
                  />
                </div>
              );
            })}
          </div>
        </div> */}

        {/* Chart */}
        <div className="col-6">
          <div className="card full-height">
            <Chart
              options={
                store === "theme-mode-dark"
                  ? {
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    }
                  : {
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    }
              }
              series={chartOptions.series}
              type="histogram"
              height="100%"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="card full-height">
            <Chart
              options={
                store === "theme-mode-dark"
                  ? {
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    }
                  : {
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    }
              }
              series={chartOptions.series}
              type="heatmap"
              height="100%"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card__header">
              <h3>top customers</h3>
            </div>
            <div className="card__body">
              <Table
                headData={topCustomers.head}
                renderHead={(item, index) => renderCustomerHead(item, index)}
                bodyData={topCustomers.body}
                renderBody={(item, index) => renderCustomerBody(item, index)}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="card">
            <div className="card__header">
              <h3>latest orders</h3>
            </div>
            <div className="card__body">
              <Table
                headData={latestOrders.header}
                renderHead={(item, index) => renderOrderHead(item, index)}
                bodyData={latestOrders.body}
                renderBody={(item, index) => renderOrderBody(item, index)}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;

// axios.get(`/getItem/${props.item.item_id}`).then((res)=>{
//     setItem(res.data)

// return (
//   <div className='item-card'>
//     {item==null
//     ?
//     <p>loading</p>
//     :
//       <div className='Item'>
//       <p>Item!</p>
//       <p>{item.name}</p>
//       <button onClick={deincrement}>-</button>
//       <button onClick={increment}>+</button>
//     </div>
// }
//   </div>
// );
