import {
  doc,
  auth,
  getDoc,
  database,
  signInWithEmailAndPassword,
} from "../db/firebase";
import "./login.scss";
import Swal from "sweetalert2";
import { styled } from "@mui/system";
import logo from "../assets/Hizzy-Homesphere.png";
import { Button, TextField } from "@mui/material";
import React, { useEffect, useCallback } from "react";

const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "#01B5E7",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#01B5E7",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#01B5E7",
    },
  },
}));

export default function LoginPage({ history }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const checkUser = useCallback(async () => {
    let uid = await localStorage.getItem("uid");
    if (uid) history.replace("/admin/dashboard");
  }, [history]);

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email or password is empty",
        });
      } else {
        await signInWithEmailAndPassword(auth, email, password)
          .then(async (user) => {
            getDoc(doc(database, "hemisphere-admins", user.user.uid)).then(
              async (doc) => {
                if (doc.exists()) {
                  await localStorage.setItem("uid", user.user.uid);
                  history.replace("/admin/dashboard");
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You are not an homesphere admin",
                  });
                }
              }
            );
          })
          .catch((error) => {
            Swal.fire({ text: error, icon: "error", title: "Oops..." });
          });
      }
    } catch (e) {
      Swal.fire({ text: e, icon: "error", title: "Oops..." });
    }
  };

  return (
    <section>
      <div className="box">
        <div className="square" id="square0" />
        <div className="square" id="square1" />
        <div className="square" id="square2" />
        <div className="square" id="square3" />
        <div className="square" id="square4" />
        <div className="square" id="square5" />
        <div className="container">
          <div className="form">
            <div style={{ textAlign: "center" }}>
              <img src={logo} alt="logo" width={230} />
              <h3>Login To Your Account</h3>
            </div>
            <CssTextField
              fullWidth
              value={email}
              variant="outlined"
              label="Email Address"
              onChange={(e) => setEmail(e.target.value)}
              style={{ color: "white", marginBottom: "20px" }}
            />
            <CssTextField
              fullWidth
              value={password}
              label="Password"
              type={"password"}
              variant="outlined"
              style={{ color: "white" }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleLogin}
              fullWidth
              style={{
                backgroundColor: "#01B5E7",
                color: "white",
                marginTop: "20px",
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
