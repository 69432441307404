import "./register.css";
import React from "react";
import { useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";

const NotifyUser = () => {
  const [title, setTitle] = React.useState("");
  const [message, setMessage] = React.useState("");
  const usersList = useSelector((state) => state.storeDb.users) || [];
  const buildersList = useSelector((state) => state.storeDb.builders) || [];

  const sendUsersNotifications = async () => {
    for (let i = 0; i < usersList.length; i++) {
      fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAAewBNsQo:APA91bF584KVBVT9xEkTYgeOULmakk2sfQGqff8uCrrtsYlJTXz8NPEy5TLmrv55tfpelFwvkxOUCJy3R9XRdElvM5K7kHTkNwChjD1L8aBhtYwe-1bQsS57xm6iVUEf2DoTfTicOj7e",
        },
        body: JSON.stringify({
          to: usersList[i].pushToken,
          notification: { title, body: message, sound: "default" },
        }),
      });
      setTitle("");
      setMessage("");
    }
  };

  const sendBuildersNotifications = async () => {
    for (let i = 0; i < buildersList.length; i++) {
      fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "key=AAAAewBNsQo:APA91bF584KVBVT9xEkTYgeOULmakk2sfQGqff8uCrrtsYlJTXz8NPEy5TLmrv55tfpelFwvkxOUCJy3R9XRdElvM5K7kHTkNwChjD1L8aBhtYwe-1bQsS57xm6iVUEf2DoTfTicOj7e",
        },
        body: JSON.stringify({
          to: buildersList[i].pushToken,
          notification: { title, body: message, sound: "default" },
        }),
      });
      setTitle("");
      setMessage("");
    }
  };

  return (
    <div>
      <div className="card">
        <h2>Notify Users</h2>
        <TextField
          fullWidth
          autoFocus
          label="Title"
          title="title"
          value={title}
          variant="outlined"
          autoComplete="title"
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: "10px", marginTop: "20px" }}
        />
        <TextField
          fullWidth
          autoFocus
          title="title"
          value={message}
          label="Message"
          variant="outlined"
          autoComplete="title"
          style={{ margin: 0 }}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={sendUsersNotifications}
          disabled={title.length === 0 || message.length === 0}
          style={{
            color: "white",
            height: "50px",
            marginTop: "10px",
            borderRadius: "5px",
            background: "linear-gradient(to bottom, #ff0101 0%, #7c0404 200%)",
          }}
        >
          Send Notification
        </Button>
      </div>
      <div className="card">
        <h2>Notify Builders</h2>
        <TextField
          fullWidth
          autoFocus
          title="title"
          value={title}
          label="Title"
          variant="outlined"
          autoComplete="title"
          onChange={(e) => setTitle(e.target.value)}
          style={{ marginBottom: "10px", marginTop: "20px" }}
        />
        <TextField
          fullWidth
          autoFocus
          title="title"
          value={message}
          label="Message"
          variant="outlined"
          autoComplete="title"
          style={{ margin: 0 }}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={sendBuildersNotifications}
          disabled={title.length === 0 || message.length === 0}
          style={{
            color: "white",
            height: "50px",
            marginTop: "10px",
            borderRadius: "5px",
            background: "linear-gradient(to bottom, #ff0101 0%, #7c0404 200%)",
          }}
        >
          Send Notification
        </Button>
      </div>
    </div>
  );
};

export default NotifyUser;
