import "./dashboard.css";
import Lottie from "lottie-react";
import Badge from "../badge/Badge";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Table from "../components/table/Table";
import particles from "../assets/lotties/shapes.json";
import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ThemeAction from "../redux/actions/ThemeAction";
import StatusCard from "../components/status-card/StatusCard";
import { collection, getDocs, database, where, query } from "../db/firebase";

const chartOptions = {
  series: [
    {
      name: "Builders",
      data: [40, 70, 10, 80, 36, 80, 46, 98, 72],
    },
    {
      name: "Revenue",
      data: [49, 26, 53, 68, 34, 65, 13, 65],
    },
  ],
  options: {
    color: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const topCustomers = {
  head: ["user", "total orders", "total spending"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const renderCustomerHead = (item, index) => {
  return <th key={index}>{item}</th>;
};

const renderCustomerBody = (item, index) => (
  <tr key={index}>
    <td>{item.user}</td>
    <td>{item.order}</td>
    <td>{item.price}</td>
  </tr>
);

const latestOrders = {
  header: ["order id", "user", "total price", "date", "status"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const orderStatus = {
  shipping: "primary",
  pending: "warning",
  paid: "success",
  refund: "danger",
};

const renderOrderHead = (item, index) => {
  return <th key={index}>{item}</th>;
};

const renderOrderBody = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.id}</td>
      <td>{item.user}</td>
      <td>{item.price}</td>
      <td>{item.date}</td>
      <td>
        <Badge type={orderStatus[item.status]} content={item.status} />
      </td>
    </tr>
  );
};

const Dashboard = ({ history }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.storeDb.mode);
  const buildersList = useSelector((state) => state.storeDb.builders) || [];

  const usersList = useSelector((state) => state.storeDb.users) || [];

  useEffect(() => {
    dispatch(ThemeAction.getTheme());
  });

  const getBuilders = useCallback(async () => {
    const builders = [];
    getDocs(
      query(
        collection(database, "builders"),
        where("company", "==", "homesphere")
      )
    ).then((docSnap) => {
      docSnap.forEach((doc) => builders.push(doc.data()));
      dispatch({ type: "SET_BUILDERS", payload: builders });
    });
  }, [dispatch]);

  const getCustomers = useCallback(async () => {
    const users = [];
    getDocs(
      query(collection(database, "users"), where("company", "==", "homesphere"))
    ).then((docSnap) => {
      docSnap.forEach((doc) => users.push(doc.data()));
      dispatch({ type: "SET_USERS", payload: users });
    });
  }, [dispatch]);

  useEffect(() => {
    getBuilders();
    getCustomers();
  }, [dispatch, getCustomers, getBuilders]);

  return (
    <div className="row">
      <div className="col-12">
        <h2 className="page-header">Dashboard</h2>
        <div className="dashboard-header-wrapper" style={{ padding: "15px" }}>
          <div className="dashboard-header-image">
            <Lottie animationData={particles} loop={true} setSpeed={0} />
          </div>
          <div className="dashboard-header-text">
            <div className="header-top">
              <h1 className="text">Homesphere Builder Dashboard</h1>
            </div>
            <Typography sx={{ fontSize: "20px", marginTop: "30px" }}>
              helping the world go greener one home at a time while improving
              your reputation and bottom line.
            </Typography>
          </div>
        </div>
      </div>
      <div className="col-8 fullWidthOnMobile">
        <div className="card full-height">
          <Chart
            options={
              store === "theme-mode-dark"
                ? { ...chartOptions.options, theme: { mode: "dark" } }
                : { ...chartOptions.options, theme: { mode: "light" } }
            }
            series={chartOptions.series}
            type="line"
            height="100%"
          />
        </div>
      </div>
      <div className="col-4 fullWidthOnMobile">
        <StatusCard
          icon="bx bx-user-pin"
          title="Total Builders"
          count={buildersList.length}
        />
        <StatusCard
          icon="bx bx-user"
          title="Total Customers"
          count={usersList.length}
        />
      </div>
      <div className="col-4 fullWidthOnMobile">
        <div className="card">
          <div className="card__header">
            <h3>top customers</h3>
          </div>
          <div className="card__body">
            <Table
              headData={topCustomers.head}
              renderHead={(item, index) => renderCustomerHead(item, index)}
              bodyData={topCustomers.body}
              renderBody={(item, index) => renderCustomerBody(item, index)}
            />
          </div>
          <div className="card__footer">
            <Link to="/">view all</Link>
          </div>
        </div>
      </div>
      <div className="col-8 fullWidthOnMobile">
        <div className="card">
          <div className="card__header">
            <h3>latest orders</h3>
          </div>
          <div className="card__body">
            <Table
              headData={latestOrders.header}
              renderHead={(item, index) => renderOrderHead(item, index)}
              bodyData={latestOrders.body}
              renderBody={(item, index) => renderOrderBody(item, index)}
            />
          </div>
          <div className="card__footer">
            <Link to="/">view all</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
