import React from "react";
import { Route, Switch } from "react-router-dom";

import Users from "../pages/Users";
import Invite from "../pages/Invite";
import Analytics from "../pages/Analytics";
import Dashboard from "../pages/Dashboard";
import Notifications from "../pages/Notifications";

const Routes = () => {
  return (
    <Switch>
      <Route path="/admin/users" component={Users} />
      <Route path="/admin/analytics" component={Analytics} />
      <Route path="/admin/invite-builders" component={Invite} />
      <Route path="/admin/dashboard" exact component={Dashboard} />
      <Route path="/admin/notifications" component={Notifications} />
    </Switch>
  );
};

export default Routes;
