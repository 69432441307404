import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Layout from "./components/layout/Layout.jsx";
import AuthLayout from "./components/layout/Auth.jsx";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/admin" render={(props) => <Layout {...props} />} />
        <Redirect to="/auth/login" />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
